import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { detailsOrder } from "../actions/orderActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from "../constants/orderConstants";
import axios from "axios";

export default function OrderScreen(props) {

  const cart = useSelector((state) => state.cart);
  const orderId = props.match.params.id;
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;


  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;
  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !order ||
      successPay ||
      successDeliver ||
      (order && order._id !== orderId)
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(detailsOrder(orderId));
    }
    window.scrollTo(0, 0);
  }, [dispatch, orderId, sdkReady, successPay, successDeliver, order]);

  const orderCategories = [];

  const getOrderItems = () => {
    if (order) {
      console.log(order);
      order.orderItems.map((item, key) => {
        console.log(item.category);
        orderCategories.push(item.category);
      });
    } else {
      return null;
    }
  };

  getOrderItems();

  const placeOrderHandler = async () => {

    await axios.post("/create-checkout-session", cart)
      .then((response) => {
        window.location = response.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <div className="order-screen">
        <ul className="centerThisUL">
          <li>
            <div className="card card-body">
              <h2 className="violet">Order Summary</h2>

              <ul className="centerThisSecondUL">
                <li>
                  <h3 className="violet">Pay Here:</h3>
                </li>
                <br />

                <li>
                  <div className="row">
                    <div>
                      <strong>Order Total:</strong>
                    </div>
                    <div>
                      <strong>€{order.totalPrice.toFixed(2)}</strong>
                    </div>
                  </div>
                </li>
                {!order.isPaid && (
                  <li className="payPalSetupJob">
                    {!sdkReady ? (
                      <LoadingBox></LoadingBox>
                    ) : (
                      <>
                        {errorPay && (
                          <MessageBox variant="danger">{errorPay}</MessageBox>
                        )}
                        {loadingPay && <LoadingBox></LoadingBox>}
                        <br />
                        <button
                          id="greenButton"
                          className="greenButtonLight checkout cursorPointer"
                          onClick={placeOrderHandler}
                          type="submit"
                        >
                          Pay with Stripe
                        </button>
                      </>
                    )}
                  </li>
                )}

                <li>
                  {order.orderItems.map((item) => {
                    console.log("order item: ", item);
                    return (
                      <div>
                        <ul className="cart-list-container">
                          <h2>Order:</h2>
                          <hr />
                          <li>
                            <div className="cart-image">
                              <img src={item.image} alt="product" />
                            </div>
                            <div className="cart-name">
                              <div>
                                <Link
                                  to={"/product/" + item.product}
                                  className="black-text"
                                >
                                  (x{item.qty}) {item.name}
                                </Link>
                              </div>
                              <p className="">€{item.price}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </li>
              </ul>
            </div>

            <div>
              <p>
                {order.orderItems.map((item, key) => {
                  return item.category.includes("Shop") ||
                    item.category.includes("") ? (
                    <div className="centerThisSecondUL">
                      <h2 className="violet">Delivery Details:</h2>
                      <div>
                        <p className="centerThis">
                          {console.log(order)}
                          {order.shippingAddress.fullName}
                          <br />
                          {order.shippingAddress.address}
                          <br />
                          {order.shippingAddress.city}
                          <br />
                        </p>
                        <p className="centerThis">
                          Thank you for your custom, we hope you enjoy the food.
                        </p>
                      </div>
                    </div>
                  ) : null;
                })}
              </p>
            </div>

            <div>
              <p>
                {order.orderItems.map((item, key) => {
                  return item.category.includes("Takeaway") ||
                    item.category.includes("Snack") ||
                    item.category.includes("Cheese") ||
                    item.category.includes("Special") ? (
                    <div className="centerThisSecondUL">
                      <h2 className="violet">Takeaway for Collection:</h2>
                      <div>
                        <p className="centerThis">
                          <u>
                            Collect from {order.shippingAddress.collectionPoint}{" "}
                            between 5:00pm - 5:30pm on Friday.
                          </u>
                        </p>
                        <p className="centerThis">
                          Thank you for your custom, we hope you enjoy the food.
                        </p>
                      </div>
                    </div>
                  ) : null;
                })}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
