import React, { useState, useEffect } from "react";
import axios from "axios";

function EditBlog() {

    const [ postBlog, setPostBlog ] = useState({
        blogtitle: "",
        blogtext: ""
    })
    const handleChangeDetails = (event) => {
        setPostBlog({
          ...postBlog,
          [event.target.name]: event.target.value,
        });
      };

      const handleSubmitDetails = (event) => {
        event.preventDefault();
        axios.post("/api/blog/", postBlog)
          .then(response => {
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const [getBlogs, setGetBlogs] = useState([]);
      useEffect(() => {
        axios.get('/api/blog/')
        .then(response => {
          setGetBlogs(response.data);
        })
        .catch(error => {
        });
      }, []);


      const deleteBlog = (item) => {
        axios.delete(`/api/blog/${item.id}`)
        .then(response => {
          window.location.reload();
        })
        .catch(error => {
        });
      }

  return (
    <div className="blog-editor">
      <h1>Add Blog</h1>
      <form className="editTakeawayForm" onSubmit={(event) => handleSubmitDetails(event)} >
        <label>Title</label>
        <input
          name="blogtitle"
          onChange={(event) => handleChangeDetails(event)}
        />
        <br />

        <label>Text:</label>
        <textarea
          name="blogtext"
          placeholder=""
          rows="25"
          onChange={(event) => handleChangeDetails(event)}
        />
        <br />
        <button type="submit">Add Blog</button>
        <br />
      </form>

      <div>
        <h1>Blogs on display</h1>
        {getBlogs.map(item => {
          return (<div className="blogwrapper">
            <h2>{item.blogtitle}</h2><button onClick={() => deleteBlog(item)}>Delete?</button>
            <p>{item.blogtext}</p>
            <hr/>
          </div>)
        })}
      </div>
    </div>
  );
}

export default EditBlog;
