import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createReview, detailsProduct } from "../actions/productActions";
import { PRODUCT_REVIEW_CREATE_RESET } from "../constants/productConstants";
// import testproduct from "../imagesByEoin/testproduct.jpeg";

export default function ProductScreen(props) {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  const [qty, setQty] = useState(1);
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successReviewCreate } = productReviewCreate;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (successReviewCreate) {
      window.alert("Review Submitted Successfully");
      setRating("");
      setComment("");
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }
    dispatch(detailsProduct(productId));
  }, [dispatch, productId, successReviewCreate]);

  const handleAddToCart = () => {
    props.history.push("/cart/" + props.match.params.id + "?qty=" + qty);
  };

  return (
    <div className="linebreaks">
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error} </div>
      ) : (
        <>
          <div className="details">
            <div className="details-info">
              <img
                className="product-image-to-fit"
                src={product.image}
                alt="product"
              ></img>
              <div className="data-on-product">
                <ul className="">
                  <li>
                    <h3>{product.name}</h3>
                  </li>
                  <li>
                    <div>
                      {product.description ? product.description : null}
                    </div>
                  </li>
                  <li>{product.price ? `Price: €${product.price}` : null}</li>
                  <li>
                    <p>
                      {" "}
                      Qty:{" "}
                      <select
                        value={qty}
                        onChange={(e) => {
                          setQty(e.target.value);
                        }}
                      >
                        {[...Array(product.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </select>
                    </p>
                  </li>
                  <li>
                    {product.countInStock > 0 && (
                      <button
                        id=""
                        onClick={handleAddToCart}
                        className="greenButtonLight"
                      >
                        Add to Cart
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
      <br />
      <div>
        <Link className="back-to-result" to="/shop">
          <u>Go Back to Shop?</u>
        </Link>
      </div>
      <br />
    </div>
  );
}
