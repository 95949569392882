import React, { useEffect } from "react";
import MyForm from "../components/MyForm";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '0 auto', maxWidth: '90%'}}
    >
      <MyForm />
    </div>
  );
}

export default ContactUs;
