import React from 'react';

function FailurePayPage(){

    return (
        <section className="successContainer">

            <p className="centerThis textAlignCentre">This order was canceled and no funds were taken from your account.
            <br/><br/>
            We hope you check out our products again in the future. 
             </p>
        </section>
    );
}

export default FailurePayPage;