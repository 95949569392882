import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveShippingAddress } from "../actions/cartActions";
import { useHistory } from "react-router-dom";

export default function ShippingAddressScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);

  const { userInfo } = userSignin;
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  if (!userInfo) {
    props.history.push("/signin");
  }

  const [fullName, setFullName] = useState(shippingAddress.fullName);
  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [phone, setPhone] = useState(shippingAddress.phone);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState("Westmeath");
  const [email, setEmail] = useState(shippingAddress.email);
  const [instructions, setInstructions] = useState(
    shippingAddress.instructions
  );
  const dispatch = useDispatch();

  const history = useHistory();

  const submitHandler = () => {
    dispatch(
      saveShippingAddress({
        fullName,
        address,
        phone,
        city,
        postalCode,
        country,
        instructions,
        email,
      })
    );
    history.push("/placeorder");
  };

  return (
    <div>
      <div className="aboutContainer">
        <form onSubmit={submitHandler}>
          <ul className="form-container">
            <li>
              <h2>Your Details:</h2>
            </li>

            <li>
              <label htmlFor="fullName">Full Name:</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder=""
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              ></input>
            </li>

            <li>
              <label htmlFor="emailAddress">Email Address:</label>
              <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>
            </li>

            <li>
              <label htmlFor="phonenumber">Phone Number:</label>
              <input
                type="text"
                name="phonenumber"
                id="phonenumber"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              ></input>
            </li>

            <li>
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                name="address"
                id="address"
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
              ></input>
            </li>

            <li>
              <label htmlFor="city">Town:</label>
              <input
                type="text"
                name="city"
                id="city"
                value={city}
                required
                onChange={(e) => setCity(e.target.value)}
              ></input>
            </li>

            <li>
              <label htmlFor="country">County:</label>
              <input
                type="text"
                name="country"
                id="country"
                value={country}
                required
                onChange={(e) => setCountry(e.target.value)}
              ></input>
            </li>

            <li>
              <label>Country:</label>
              <input type="text" value={"Ireland"} disabled></input>
            </li>

            <li>
              <label htmlFor="postalCode">
                Eircode:{" "}
                <a className="black-text" href="https://finder.eircode.ie/#/">
                  <i className="red-text">*Click here to Find Your Eircode</i>
                </a>
              </label>
              <input
                type="text"
                name="postalCode"
                id="postalCode"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              ></input>
            </li>

            <li>
              <label htmlFor="additional">
                Optional Note:
              </label>
              <textarea
                type="text"
                name="additional"
                id="additional"
                rows="8"
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
              ></textarea>
            </li>

            <li>
              <button type="submit" className="button primary">
                Continue
              </button>
            </li>
          </ul>
        </form>
      </div>
      <br />
    </div>
  );
}
