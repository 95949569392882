import React from "react";
import { useHistory } from "react-router-dom";
import "./Footer.css";
import instagram from "../imagesByEoin/instagram.svg";

function Footer() {
  const history = useHistory();

  const gohome = (e) => {
    history.push("/");
  };

  return (
    <footer className="footer">
      <nav className="footerNavigation">
        <a href="/">
          <p>Home</p>
        </a>
        <a href="/about">
          <p>About</p>
        </a>
        <a href="/shop">
          <p>Products</p>
        </a>
        <a href="/stockists">
          <p>Stockists</p>
        </a>
        <a href="/events">
          <p>Events</p>
        </a>
        <a href="/readblog">
          <p>Blog</p>
        </a>
        <a href="/contact">
          <p>Contact</p>
        </a>
      </nav>

      <hr />

      <div className="links">
        <form
          action="https://gmail.us19.list-manage.com/subscribe/post?u=5f345ee5b52850316f8e104b4&amp;id=e9b9605d2b"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <section className="newsLetterFooter">
            <label>
              <u>
                <h5 id="centreTheNLText">Newsletter:</h5>
              </u>
              <p className="centerAlignText">
                Sign up to our newsletter and keep up to date! We
                share seasonal info and inspiration to help you in your journey
                in natural living! Be part of our local sustainable community,
                sharing knowledge in food, farming &amp; nature!{" "}
              </p>
            </label>
            <div>
              <br />
              <input type="text" placeholder="your@email.here" />
              <input
                type="submit"
                className="subscribeButton"
                value="Sign Up"
                name="subscribe"
              />
            </div>
          </section>
        </form>
      </div>

      <hr />

      <div className="footerHeading changeMouse">
        <h3 onClick={gohome}>4 Hands Food Studio</h3>
      </div>

      <div className="instagramDiv">
        <a
          className="linkToInstagram"
          href="https://www.instagram.com/4hands_food_studio/?hl=en"
        >
          <img
            className="instagram-icon"
            src={instagram}
            alt="Link to Instagram"
          />
        </a>
      </div>

      <div className="bottom">
        <a>
          <p>&copy; 4 Hands Food Studio 2023</p>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
