import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../sectionsByEoin/Homepage.css";
import { useDispatch } from "react-redux";
import { listProducts } from "../actions/productActions";
import { Link } from "react-router-dom";
import homeimage from "../imagesByEoin/homeimage.JPG";
import imageOne from "../imagesByEoin/imageOne.jpg";
import peas from "../imagesByEoin/peas.jpg";

export default function HomeScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(listProducts({}));
  }, [dispatch]);

  const goToProducts = (e) => {
    history.push("/shop");
  };

  return (
    <div>
      <div className="homeImages">
        <div className="homepageContainer">
          <img
            className="homeImageToSize"
            src={homeimage}
            alt="4 Hands Food Studio's Food"
          />
        </div>
      </div>

      <div className="homepageContainer">
        <section className="first homepageSection">
          <div className="violet">
            <h3>The Fermented Goods</h3>
          </div>
          <p className="paraforalignment">
            We currently offer a range of sauerkraut, kimchi, kombucha,
            probiotic juice, raw apple vinegar, and sprouted whole foods.
            <br /> We focus on a starting point: the raw produce.
            <br />
            <br />
            The ingredients we use have to be alive, chemical free and ethically
            produced.
            <br />
            We sell in{" "}
            <Link className="linkToNormalise" to="/stockists">
              <u> selected stores</u>
            </Link>{" "}
            and our{" "}
            <Link className="linkToNormalise" href="/shop">
              <u>online store. </u>
            </Link>
          </p>
          <div id="orangeButton" className="redButton" onClick={goToProducts}>
            <p>
              <b>CHECK OUT HERE</b>
            </p>
          </div>
        </section>

        <section className="sb-02">
          <div
            style={{
              backgroundColor: "#e0a47c",
              color: "black",
              padding: "2rem",
              width: "100%",
            }}
          >
            <h2>4 hands food studio monthly subscription</h2>
            <p>
              Our monthly offering supplies you with a selection of our
              naturally fermented products for you to introduce into your diet,
              increasing the diversity of beneficial probiotics to your gut and
              adding wonderful bursts of flavor to your plate!
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ maxWidth: "80%" }}
                src={imageOne}
                alt="Bottles of Kombucha"
              />
            </div>
            <p>
              <i>
                "Thank you for nourishing us all year with such conscious tasty
                food made with love."
              </i>
              <br />
              Suzanne, Galway
            </p>
          </div>

          <div style={{ padding: "2rem", paddingTop: 0, width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ maxWidth: "80%", paddingTop: 0, marginTop: 0 }}
                src={homeimage}
                alt="Kombucha"
              />
            </div>
            <h2>How to Order</h2>
            <p>
              Your <u>monthly subscription gets sent directly</u> to your door
              via An Post.
              <br />
              <br />
              <div>
                Email us your:
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li>NAME</li>
                  <li>ADDRESS</li>
                  <li>MOBILE</li>
                </ul>
                <br />
                We will send you a{" "}
                <a href="https://www.sumup.com/en-ie/payment-links/">
                  <u>SumUp</u>
                </a>{" "}
                link for payment and send out your first pack! Every month we
                will contact you before sending a new order, convenient and
                hassle free!
              </div>
              <div>
                Connecting directly with our customers allows us to build
                relationships and get to know your personal needs.
              </div>
              <p>Get yours right now by emailing 4handsf@gmail.com</p>
            </p>
          </div>

          <div
            style={{
              background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('${peas}')`,
              backgroundSize: "cover",
              width: "100%",
              height: "auto",
              padding: "2rem",
            }}
          >
            <h2 style={{ color: "#e0a47c" }}>What to expect</h2>
            <p>Starter monthly pack for €60 contains:</p>
            <ul>
              <li>Kombucha x6</li>
              <li>Sauerkraut x2</li>
              <li>Probiotic Carrot Juice x1</li>
              <li> Apple Cider Vinegar x1</li>
              <li>Fermented Chilli Jam x1</li>
              <li>Irish Grown Peas x 500g</li>
            </ul>
            Additional options that you can add to your monthly order:
            <ul>
              <li>Irish sprouted pea hummus</li>
              <li>Dense seedy sourdough</li>
              <li>Granola</li>
              <li>Seasonal jams</li>
              <li>Celtic sea salt</li>
            </ul>
            <p>
              <i>
                Everything 4 Hands produce is absolutely delicious. From their
                refreshing komuchas to their flavoursome hummus pots, jimchi,
                chilli jams and fermeneted vegetable jars, there is something
                for everyone to love! A real gem based in the midlands.
              </i>
              <br />
              Blathnaid, Offaly
            </p>
            <br />
            <p>
              <i>
                I have tried different products but your kombucha is the best to
                help my stomach with bloating. I love your jombucha so much!
              </i>
              <br />
              Stu, Derry
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}
