import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createOrder } from "../actions/orderActions";
import axios from "axios";

export default function PlaceOrderScreen(props) {
  const cart = useSelector((state) => state.cart);
  if (!cart.paymentMethod) {
    props.history.push("/payment");
  }
  const orderCreate = useSelector((state) => state.orderCreate);
  const { success, order } = orderCreate;
  const toPrice = (num) => Number(num.toFixed(2));
  cart.itemsPrice = toPrice(
    cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0)
  );
  cart.shippingPrice = 0;
  cart.taxPrice = 0;
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice;
  const dispatch = useDispatch();

  const [placeToGo, setPlaceToGo] = useState("");

  const placeOrderHandler = async () => {
    await axios
      .post(`${process.env.REACT_APP_ROOTURL}/create-checkout-session`, cart)
      .then((response) => {
        console.log(response);
        setPlaceToGo(response.data.url);
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (success) {
      dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
      window.location = placeToGo;
    }
  }, [dispatch, order, props.history, success]);

  return (
    <div>
      <div className="order-screen">
        <h2 className="violet">Place Order</h2>
        <div className="centerThisUL">
          <div className="centerThisSecondUL">
            <div className="">
              <h2>Order Details</h2>
              {cart.cartItems.map((item) => (
                <div key={item.product}>
                  {console.log(item)}{" "}
                  <div className="makeARow">
                    <div>
                      <img
                        src={item.image}
                        alt={item.name}
                        className="small"
                      ></img>
                    </div>
                    <div className="min-30">
                      <Link to={`/product/${item.product}`}>
                        {item.name} {item.qty} x €{item.price} = €
                        {item.qty * item.price}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="centerThisSecondUL">
            <div className="card card-body">
              <p>Total Price: €{cart.totalPrice}</p>
            </div>
          </div>

          <div className="centerThisSecondUL">
            <button
              type="button"
              onClick={placeOrderHandler}
              className="greenButtonLight checkout cursorPointer"
              disabled={cart.cartItems.length === 0}
            >
              Pay with Stripe
            </button>
          </div>

          {cart?.cartItems.map((item, key) => {
            return (
              <div className="centerThisSecondUL" key={key}>
                <h2 className="violet">Delivery Details:</h2>
                <div>
                  <p className="centerThis">
                    {cart?.shippingAddress.fullName}
                    <br />
                    {cart?.shippingAddress.address}
                    <br />
                    {cart?.shippingAddress.city}
                    <br />
                  </p>
                  <p className="centerThis">
                    Thank you for your custom, we hope you enjoy the food.
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
