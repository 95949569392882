import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { payOrder } from "../actions/orderActions";

function SuccessPayPage(){

    const orderDetails = useSelector((state) => state.orderDetails);
    const { order, loading, error } = orderDetails;
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(payOrder(order));
    },[]);

    return (
        <section className="successContainer">

            <p className="centerThis textAlignCentre">Thank you for your order. We hope you enjoy your goods. <br/><br/> You will receive a receipt of payment from Stripe. </p>

        </section>
    );
}

export default SuccessPayPage;