import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ReadBlog(){

    const [ blogOnTap, setBlogOnTap ] = useState([]);
    



    useEffect(() => {
        axios.get('/api/blog')
        .then(response => {
            setBlogOnTap(response.data)
        })
        .catch(error => {
            console.log(error);
        })
    }, [blogOnTap]);

    return (
        <section className="aboutContainer">
        {blogOnTap.map((item, key) => {
            return (
                <div className="blogProvider">
                <h2>{item.blogtitle}</h2>
                <p className="aboutContainer">{item.blogtext}</p>
                <hr/>

                </div>
            )
        })}
        </section>
    );
}

export default ReadBlog;
