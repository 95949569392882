import React from "react";
import "../index.css";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/f/mdopppvr"
        method="POST"
        style={{ maxWidth: '90%', display: 'flex', }}
      >
        <ul className="form-container">
          <li>
            <div>
              <h2 className="violet">Contact Us</h2>
            </div>
            <p>
              Feel free to get in touch with any questions, about orders, to
              give feedback or anything else. <br />
              <br />
              Phone:{" "}
              <a href="tel:+353871270142">
                <u>+353871270142</u>
              </a>
            </p>
            <hr />
          </li>

          <label>Name:</label>
          <input type="text" name="name" />
          <br />

          <label>Email:</label>
          <input type="email" name="email" />
          <br />

          <label>Postage Address:</label>
          <input type="address" name="email" />
          <br />

          <label>Eircode:</label>
          <input type="eircode" name="email" />
          <br />

          <label>Phone Number:</label>
          <input type="text" name="number" />
          <br />

          <label>Message:</label>
          <textarea type="textarea" name="message" rows="8" />
          <br />

          {status === "SUCCESS" ? (
            <p>Thanks you for your message. We will respond promptly.</p>
          ) : (
            <input
              type="submit"
              className="contactButton"
              value="Send Message"
            />
          )}
          {status === "ERROR" && (
            <p> There was an error. You can reach us on 4handsf@gmail.com</p>
          )}
        </ul>
      </form>

    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
